'use client';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Define arrays for the two groups of links.
  const topLinksArray = [
    { label: 'Individuals', to: '/business' },
    { label: 'Business', to: '/business' },
    { label: 'Contact', to: 'mailto:team@acountpay.com' },
  ];

 

  const actionButtons = [
   
    { label: 'Merchant Login', to: 'https://dashboard.acountpay.com', variant: 'button' },
  ];

  // Common styling classes (now using the styling of the original lower navbar)
  const desktopLinkClass =
    'text-[#22201c] text-[15px] font-medium font-["DM Sans"]';
  const desktopButtonClass =
    'rounded-lg border bg-[#003366] text-white border-[#003366] px-4 py-2 text-[15px] font-bold font-["DM Sans"] transition-colors duration-200 hover:bg-[#1f4e7d] hover:text-white';

  const mobileLinkClass =
    'text-[#22201c] text-[15px] font-medium font-["DM Sans"]';
  const mobileButtonClass =
    'rounded-lg border border-gray-900 px-4 py-2 text-[15px] font-medium font-["DM Sans"] transition-colors duration-200 hover:bg-gray-900 hover:text-white';

  return (
    <header className="relative">
      {/* Single Top Navbar (desktop view) */}
      <div className="w-full h-20 bg-[#ffffff] flex items-center">
        <div className="flex items-center w-full px-8 justify-between">
          <div className="flex items-center space-x-8">
            {/* abc Logo */}
            <Link to="/" className="mr-8">
              <span className="text-xl font-sans font-bold text-[#000000]">
                acount
              </span>
            </Link>
            {/* Navigation Links */}
            <nav className="hidden lg:flex space-x-8">
              {topLinksArray.map((link) => (
                <Link key={link.label} to={link.to} className={desktopLinkClass}>
                  {link.label}
                </Link>
              ))}
            
            </nav>
          </div>
          {/* Right-side actions and mobile menu button */}
          <div className="flex items-center space-x-4">
            <div className="hidden lg:flex items-center space-x-4">
              {actionButtons.map((btn) =>
                btn.variant === 'button' ? (
                  <Link key={btn.label} to={btn.to} className={desktopButtonClass}>
                    {btn.label}
                  </Link>
                ) : (
                  <Link key={btn.label} to={btn.to} className={desktopLinkClass}>
                    {btn.label}
                  </Link>
                )
              )}
            </div>
            {/* Mobile Menu Button */}
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="lg:hidden inline-flex items-center justify-center p-2.5 text-gray-700"
            >
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu Modal */}
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-[#ffffff] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="mr-4">
              <span className="text-xl font-sans font-bold text-[#000000]">
                acount
              </span>
            </Link>
            <button
              type="button"
              className="rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6">
            {/* Mobile Navigation Links */}
            <nav className="grid gap-y-4">
              {topLinksArray.map((link) => (
                <Link
                  key={link.label}
                  to={link.to}
                  onClick={() => setMobileMenuOpen(false)}
                  className={mobileLinkClass}
                >
                  {link.label}
                </Link>
              ))}
           
            </nav>
            <div className="mt-6 grid gap-y-4">
              {actionButtons.map((btn) =>
                btn.variant === 'button' ? (
                  <Link
                    key={btn.label}
                    to={btn.to}
                    onClick={() => setMobileMenuOpen(false)}
                    className={mobileButtonClass}
                  >
                    {btn.label}
                  </Link>
                ) : (
                  <Link
                    key={btn.label}
                    to={btn.to}
                    onClick={() => setMobileMenuOpen(false)}
                    className={mobileLinkClass}
                  >
                    {btn.label}
                  </Link>
                )
              )}
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Navbar;
