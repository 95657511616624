import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-white py-12 container mx-auto px-8 lg:px-16 border-t border-l-2 border-r-2 border-double md:border-l-0 md:border-r-0">
      <div className="max-w-7xl mx-auto">
        {/* Parent grid: one column on mobile and two columns on md+ */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left container: nested grid for Company and Security with reduced horizontal gap */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-8">
            {/* Column 1: Company */}
            <div className="space-y-4">
              <h4 className="text-lg font-medium text-gray-900">Company</h4>
              <ul className="space-y-2">
                <li>
                  <Link to="/business" className="text-sm text-gray-600 hover:text-gray-900">
                    Individuals
                  </Link>
                </li>
                <li>
                  <Link to="/business" className="text-sm text-gray-600 hover:text-gray-900">
                    Business
                  </Link>
                </li>
                <li>
                  <Link to="/business" className="text-sm text-gray-600 hover:text-gray-900">
                    Company
                  </Link>
                </li>
                <li>
                  <a href="mailto:team@acountpay.com" className="text-sm text-gray-600 hover:text-gray-900">
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            {/* Column 2: Security */}
            <div className="space-y-4">
              <h4 className="text-lg font-medium text-gray-900">Security</h4>
              <ul className="space-y-2">
                <li>
                  <Link to="/privacy-policy" className="text-sm text-gray-600 hover:text-gray-900">
                    Website Privacy
                  </Link>
                </li>
                <li>
                  <Link to="/APTerms" className="text-sm text-gray-600 hover:text-gray-900">
                    Acount Terms
                  </Link>
                </li>
                <li>
                  <Link to="/APPrivacy" className="text-sm text-gray-600 hover:text-gray-900">
                    Acount Privacy
                  </Link>
                </li>
                <li>
                  <Link to="/user" className="text-sm text-gray-600 hover:text-gray-900">
                    End User Terms
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/* Right column: Copyright text */}
          <div className="flex items-center text-xs text-gray-400 text-justify justify-start md:justify-end">
            © 2025 Acount Technologies ApS. All rights reserved. CVR 45391906. Registered at Tuborg Havnevej 16, 2900 Hellerup, Denmark.
          Payment services provided by Payment Institution regulated by the German Financial Supervisory Authority (BAFIN) with identification.
          Acount is a technology company, not a licensed financial institution. All names are trademarks™ or registered trademarks® of their respective holders.
          Their usage does not imply any affiliation with or endorsement by their holders.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
