import React from 'react';

const APPrivacy = () => {
  return (
    <div className='bg-[#ffffff] text-gray-900 min-h-screen text-center flex flex-col'>
      <div className='max-w-7xl mx-auto px-8 sm:px-8 lg:px-16 py-10'>
        <h1 className='text-2xl font-bold mb-6'>AcountPay Privacy Policy</h1>
        <p className='mb-6'>
        AcountPay is launching soon for individuals.        </p>
        </div>
        </div>
    );
};
        export default APPrivacy;