'use client';

import React from 'react';
import { motion } from 'framer-motion';
import { Airplane } from 'phosphor-react';
import { Lightning } from 'phosphor-react';
import { ShieldCheck } from 'phosphor-react';
import { ArrowsLeftRight } from 'phosphor-react';
import { Scroll } from 'phosphor-react';
import { ShoppingBagOpen } from 'phosphor-react';
import { Wallet } from 'phosphor-react';
import { CaretRight } from 'phosphor-react';
import { ArrowRight } from 'phosphor-react';

import frame44 from '../images/Frame 44.png';
import frame107 from '../images/Frame 107.png';
import frame108 from '../images/Frame 108.png';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.1, delayChildren: 0.3 },
  },
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { type: 'spring', stiffness: 100 },
  },
};

export default function Business() {
  return (
    <>
      {/* Section 1: Hero Section */}
      <div className="bg-[#ffffff] py-8 overflow-hidden flex items-center">
        <div className="max-w-7xl mx-auto px-8 sm:px-8 lg:px-16 w-full">
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="h-full flex flex-col items-center justify-between gap-y-10"
          >
            {/* Text Section */}
            <motion.div className="w-full lg:w-3/4 text-left pt-16" variants={itemVariants}>
              <motion.p className="text-base font-semibold text-black mb-2" variants={itemVariants}>
           
              </motion.p>
              <motion.h1 className="text-xl font-bold justify-center tracking-tight text-black sm:text-3xl" variants={itemVariants}>
                Tired of high payments fees? We can help.
              </motion.h1>
              <motion.p className="mt-4 text-lg justify-center leading-7 text-[#333333]" variants={itemVariants}>
              Bypass traditional payment rails and their fees. Receive payments initiated directly from your users bank account to yours. Integrate Acount to your checkout, No need to change your current PSP stack and start accepting direct bank payments.
                
              </motion.p>
              <motion.div className="mt-8 flex flex-row items-center justify-start gap-x-4" variants={itemVariants}>
                <button
                  onClick={() => (window.location.href = "mailto:sales@acountpay.com")}
                  whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                  className="flex items-center justify-center rounded-md bg-[#10294f] px-6 py-3 text-sm font-semibold text-white shadow-sm transition-colors duration-200 hover:bg-[#1f4e7d] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0060E6]"
                >
                  Talk to our team
                  <ArrowRight size={20} weight="bold" className="ml-2" />
                </button>
              </motion.div>
            </motion.div>

            {/* Hero Image Section */}
            <motion.div className="w-full lg:w-2/3 flex justify-center" variants={itemVariants}>
              <motion.img
                src={frame44}
                alt="Frame 44"
                className="w-full h-auto"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.4, duration: 0.6 }}
              />
            </motion.div>
          </motion.div>
        </div>
      </div>

  {/* Section 2: Sender Portal Section (Blue Section) */}
<section className="py-8 px-8">
  <div className="max-w-7xl mx-auto bg-[#10294f] rounded-lg px-8 sm:px-8 lg:px-12 flex flex-col md:flex-row gap-y-1 gap-x-16">
    {/* Right Side (3 bullet items) */}
    <div className="md:w-full py-16 grid grid-cols-1 md:grid-cols-3 gap-12 md:gap-16 px-4 md:px-0 text-left">
      {/* Bullet 1 */}
      <div className="flex flex-col items-start gap-6 w-full">
        <div className="flex-shrink-0">
          <ArrowsLeftRight size={24} color="#ffffff" weight="bold" />
        </div>
        <div className="w-full">
          <h3 className="font-semibold text-white">Cost Efficiency</h3>
          <p className="text-sm text-white opacity-50 text-justify">Payments are initiated directly through the consumer's bank, bypassing card schemes.
          Resulting in lower transaction costs due to the elimination of interchange.</p>
        </div>
      </div>
      {/* Bullet 2 */}
      <div className="flex flex-col items-start gap-6 w-full">
        <div className="flex-shrink-0">
          <ShieldCheck size={24} color="#ffffff" weight="bold" />
        </div>
        <div className="w-full">
          <h3 className="font-semibold text-white">Enhanced Security</h3>
          <p className="text-sm text-white opacity-50 text-justify"> Payment initiation leverages the banks and MitID for strong customer authentication.
          Sensitive card details are not required here, reducing the risk of fraud.</p>
        </div>
      </div>
      {/* Bullet 3 */}
      <div className="flex flex-col items-start gap-6 w-full">
        <div className="flex-shrink-0">
          <Lightning size={24} color="#ffffff" weight="bold" />
        </div>
        <div className="w-full">
          <h3 className="font-semibold text-white">Instant Settlement</h3>
          <p className="text-sm text-white opacity-50 text-justify">Payments are sent through straksoverførsel allowing for instant payments. No need to wait days for settlement, manage your payments in the merchant dashboard.</p>
        </div>
      </div>
    </div>
  </div>
</section>








  {/* Section 3: Security Section */}
<section className="py-24 bg-[#ffffff]" id="security">
  <div className="max-w-7xl mx-auto px-16 sm:px-6 lg:px-8 text-center">
    <h2 className="text-3xl font-bold text-gray-800">AcountPay Use Cases</h2>
    <p className="mt-4 text-lg text-gray-600">Talk to our team to learn more about how we can help your use case.</p>
  </div>
  <div className="max-w-7xl mx-auto mt-12 px-12 sm:px-6 lg:px-8 grid grid-cols-1 gap-8 md:grid-cols-3">
    {/* Bullet 1 */}
    <div className="p-4 text-left flex flex-col items-start">
      <ShoppingBagOpen size={24} color="#003366" weight="bold" className="mb-4" />
      <h3 className="text-l font-semibold text-gray-800">E-Commerce</h3>
      <p className="mt-2 text-sm text-gray-600 text-justify">Accept instant bank payments for e-Commerce. Integrate AcountPay to your checkout, and accept payments from most Danish banks.</p>
    </div>
    {/* Bullet 2 */}
    <div className="p-4 text-left flex flex-col items-start">
      <Wallet size={24} color="#003366" weight="bold" className="mb-4" />
      <h3 className="text-l font-semibold text-gray-800">Platform Deposits</h3>
      <p className="mt-2 text-sm text-gray-600 text-justify">AcountPay is ideal for platform deposits. No need for customers to add card details to fund accounts. Simply select bank and account.</p>
    </div>
    {/* Bullet 3 */}
    <div className="p-4 text-left flex flex-col items-start">
      <Scroll size={24} color="#003366" weight="bold" className="mb-4" />
      <h3 className="text-l font-semibold text-gray-800">Manual Bank Transfers</h3>
      <p className="mt-2 text-sm text-gray-600 text-justify">Does your business currently use manual bank transfers? Try AcountPay to automate and improve the payment process.</p>
    </div>
  </div>
</section>









      {/* Section 4: Bento Box Section */}
      <section className="pb-24 bg-white" id="how-it-works">
        <div className="max-w-7xl mx-auto px-8 lg:px-8">
          <h2 className="text-center text-3xl font-bold text-gray-800">
            Setup and Management
          </h2>
          <p className="text-center mt-4 text-lg text-gray-600">
            Manage all aspects in your merchant dashboard. 
          </p>
          <div className="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
             {/* Box 2 */}
             <div className="flex flex-col rounded-lg border border-gray-200 bg-[#fafafa] p-6">
              <img src={frame107} alt="Digital receipts" className="w-full h-40 object-cover mb-4" />
              <h3 className="text-lg font-semibold text-gray-900">API Integration</h3>
              <p className="mt-2 text-sm text-gray-600">
                Simple secure integration in your existing checkout system, no need to change any part.
              </p>
            </div>
            
            {/* Box 1 */}
            <div className="flex flex-col rounded-lg border border-gray-200 bg-[#fafafa] p-6">
              <img src={frame108} alt="Digital letters" className="w-full h-40 object-cover mb-4" />
              <h3 className="text-lg font-semibold text-gray-900">Merchant Dashboard</h3>
              <p className="mt-2 text-sm text-gray-600">
                Manage your transactions and export for reconciliation and refunding, all in one place.
              </p>
            </div>
           
            {/* Box 3 - Blue styled box */}
            <div className="flex flex-col rounded-lg bg-[#10294f] p-6">
              <h3 className="text-lg font-semibold text-[#ffffff]">Get started today</h3>
              <p className="mt-2 text-sm text-[#ffffff]">
                Talk to our team to get started with AcountPay.
              </p>
              <div className="mt-auto pt-4">
                <button 
                className="flex items-center justify-center rounded-md bg-[#ffffff] px-4 py-2 text-sm font-semibold text-[#003366] hover:bg-[#fafafa]"
                  whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => window.location.href = 'mailto:sales@acountpay.com'}>
                  Talk to our team
                  <ArrowRight size={24} weight="bold" className="ml-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
